import CartButton from '@/components/cart/cart-button';
import Usps from '@/components/common/usps';
import DesignMyHomeIcon from '@/components/icons/design-my-home-icon';
import MenuSearchIcon from '@/components/icons/menu-search-icon';
import UserIcon from '@/components/icons/user-icon';
import PageBuilder from '@/components/pagebuilder/pageBuilder';
import SearchInput from '@/components/search/input';
import SearchSuggestions from '@/components/search/suggestions';
import LanguageSwitcher from '@/components/ui/language-switcher';
import { SearchProvider } from '@/contexts/search';
import { useApp } from '@/hooks/app/useApp';
import { useCustomer } from '@/hooks/customer/useCustomer';
import { ROUTES } from '@/utils/routes';
import { useSsrCompatible } from '@/utils/use-ssr-compatible';
import { Drawer } from '@components/common/drawer/drawer';
import motionProps from '@components/common/drawer/motion';
import Logo from '@components/ui/logo';
import { useUI } from '@contexts/ui.context';
import { addActiveScroll } from '@utils/add-active-scroll';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { useWindowSize } from 'react-use';
import AuthMenu from './auth-menu';
import Navigation from './navigation';
import Sidebar from './sidebar';

interface HeaderProps {
  checkout?: boolean;
}

export default function Header(props: HeaderProps) {
  const { openModal, setModalView, openSidebar, closeSidebar, displaySidebar } = useUI();
  const { t } = useTranslation(['common']);
  const { headerPromotionBlock } = useApp();
  const promotionBlock = !props.checkout ? headerPromotionBlock?.pagebuilder : undefined;
  const router = useRouter();

  const siteHeaderRef = useRef<HTMLDivElement>(null);
  const { width } = useSsrCompatible(useWindowSize(), { width: 1024, height: 0 });

  const { customer, customerExists } = useCustomer();

  function handleLogin() {
    setModalView('LOGIN_VIEW');
    return openModal();
  }

  addActiveScroll(siteHeaderRef);

  return (
    <header
      id="siteHeader"
      ref={siteHeaderRef}
      className={classNames('relative z-30 h-16 w-full sm:h-20', {
        'lg:h-36 xl:h-[152px]': !props.checkout,
        'lg:h-16 xl:h-20': props.checkout,
        'mt-8': !props.checkout && !promotionBlock,
        'mt-20': promotionBlock,
      })}
    >
      {promotionBlock && (
        <div className="fixed left-0 top-0 h-[48px] w-full text-sm md:text-base">
          <div className="flex h-full">
            <PageBuilder
              pagebuilder={promotionBlock}
              noWrapper={true}
            />
          </div>
        </div>
      )}
      {!props.checkout && (
        <div
          className={classNames(
            'fixed left-0 top-0 flex h-8 w-full items-center justify-center bg-white text-sm xl:text-base',
            {
              'mt-12': !!promotionBlock,
            },
          )}
        >
          <Usps />
        </div>
      )}
      <div
        className={classNames(
          'innerSticky body-font fixed top-0 h-16 w-full bg-white px-4 text-gray-700 sm:h-20 md:px-8 lg:px-6 2xl:px-16',
          {
            'lg:h-36 xl:h-[160px]': !props.checkout,
            'lg:h-16 xl:h-20': props.checkout,
            'mt-8': !props.checkout && !promotionBlock,
            'mt-20': promotionBlock,
          },
        )}
      >
        <div
          className={classNames(
            'relative mx-auto flex h-full w-full max-w-[1920px] items-center justify-between space-x-4 xs:space-x-5 sm:space-x-7 lg:h-auto lg:space-x-0',
            {
              'lg:mt-3 xl:mt-5': !props.checkout,
              'lg:py-1.5 xl:py-3': props.checkout,
            },
          )}
        >
          <button
            aria-label="Menu"
            className="flex flex-shrink-0 flex-col items-center justify-center outline-none focus:outline-none lg:hidden"
            onClick={() => openSidebar()}
          >
            <MenuSearchIcon
              width="35px"
              height="35px"
            />
          </button>

          <div className="flex-1 lg:flex-none">
            <Logo className="mx-auto w-[150px] py-4 sm:w-[200px] lg:w-auto" />
          </div>

          <AuthMenu
            isAuthorized={!!customerExists}
            href={ROUTES.ACCOUNT}
            className="relative flex h-auto flex-shrink-0 transform items-center justify-center focus:outline-none lg:hidden"
            btnProps={{
              className: 'flex-shrink-0 focus:outline-none',
              children: <UserIcon />,
              onClick: handleLogin,
            }}
          >
            <>
              <UserIcon loggedIn={true} />
            </>
          </AuthMenu>

          <CartButton className="lg:hidden" />

          {!props.checkout && (
            <div className="relative me-auto hidden w-full px-12 lg:block">
              <SearchProvider>
                <SearchInput />
                <SearchSuggestions />
              </SearchProvider>
            </div>
          )}

          <div className="ms-auto hidden flex-shrink-0 items-center justify-end space-s-6 lg:flex lg:space-s-5 xl:space-s-8 2xl:space-s-10">
            <div
              onClick={() => router.push(ROUTES.DESIGN_MY_HOME)}
              className="flex flex-shrink-0 items-center"
            >
              <div className="relative flex h-auto flex-shrink-0 transform cursor-pointer items-center justify-center bg-[#F9F9F9] pl-[5px] pr-[15px] text-icon hover:text-action focus:outline-none">
                <span className="absolute left-[-15px] top-[-9px] bg-[#e2455a] px-[5px] py-[5px] text-xs font-semibold text-white">
                  {t('text-badge-new')}
                </span>
                <DesignMyHomeIcon />
                <span className="ml-3 text-sm font-semibold xl:text-base">{t('text-design-my-home')}</span>
              </div>
            </div>
            <div className="flex-shrink-0">
              <AuthMenu
                isAuthorized={!!customerExists}
                href={ROUTES.ACCOUNT}
                className="relative flex h-auto flex-shrink-0 transform items-center justify-center text-icon hover:text-action focus:outline-none"
                btnProps={{
                  className: 'focus:outline-none',
                  children: (
                    <>
                      <UserIcon />
                      <span className="ml-3 text-sm font-semibold xl:text-base">{t('text-login')}</span>
                    </>
                  ),
                  onClick: handleLogin,
                }}
              >
                <>
                  <UserIcon loggedIn={true} />
                  <span className="ml-3 text-sm font-semibold xl:text-base">{customer?.firstname}</span>
                </>
              </AuthMenu>
            </div>
            <CartButton />
            <div className="flex-shrink-0">
              <LanguageSwitcher />
            </div>
          </div>
        </div>

        {!props.checkout && <Navigation />}
      </div>
      {width <= 1024 && (
        <Drawer
          open={displaySidebar}
          onClose={closeSidebar}
          contentWrapperStyle={{
            width: undefined,
            left: 0,
          }}
          {...motionProps}
        >
          <Sidebar />
        </Drawer>
      )}
    </header>
  );
}
